



























































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Category, Connection } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

@Component({
  components: {
    UiLoading,
    UiButton,
    UiSelect2,
    UiTextEdit,
  },
})
export default class EditSageIntacctConnection extends BaseVue {
  @Prop({ required: true })
  public readonly connection!: Connection | null;

  @Prop()
  public readonly refresh?: () => void;

  accountCode = '';
  feeAccountCode = '';
  sageDigitalAssetAccountCode = '';

  isSaving = false;

  mounted() {
    const { accountCode, feeAccountCode, connectionSpecificFields } = this.connection ?? {};
    const { sageDigitalAssetAccountCode } = connectionSpecificFields ?? {};
    this.accountCode = accountCode || '';
    this.feeAccountCode = feeAccountCode || '';
    this.sageDigitalAssetAccountCode = sageDigitalAssetAccountCode || '';
  }

  public get categories(): Category[] {
    return this.$store.getters['categories/ENABLE_CATEGORIES'].filter((cat: Category) => {
      return cat.accountingConnectionId === this.connection?.id;
    });
  }

  public async onSave() {
    this.isSaving = true;

    try {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        connectionId: this.connection?.id ?? '',
        accountCode: this.accountCode,
        feeAccountCode: this.feeAccountCode,
        connectionSpecificFields: {
          sageIntacct: {
            sageDigitalAssetAccountCode: this.sageDigitalAssetAccountCode,
          },
        },
      };

      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation updateAccountingConnection(
            $orgId: ID!
            $connectionId: ID!
            $accountCode: String
            $feeAccountCode: String
            $connectionSpecificFields: AccountingConnectionSpecificFields
          ) {
            updateAccountingConnection(
              orgId: $orgId
              connectionId: $connectionId
              accountCode: $accountCode
              feeAccountCode: $feeAccountCode
              connectionSpecificFields: $connectionSpecificFields
            )
          }
        `,
        // Parameters
        variables: vars,
      });

      if (resp.errors) {
        this.showErrorSnackbar('Problem: ' + resp.errors.join('<br />'));
      }

      if (resp.data && resp.data.updateAccountingConnection) {
        this.showSuccessSnackbar('Updated Sage Intacct Connection!');
        this.accountCode = '';
        this.feeAccountCode = '';
        this.sageDigitalAssetAccountCode = '';

        this.back();
        if (this.refresh) this.refresh();
      }
    } catch (error: any) {
      this.showErrorSnackbar('Problem: ' + error.message);
    } finally {
      this.isSaving = false;
    }
  }

  public onHide() {
    this.$emit('close');
  }

  public back() {
    this.$emit('back');
  }
}
