<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectXero')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :hide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <v-text-field label="Consumer Key" v-model="consumerKey"></v-text-field>
          <v-text-field label="Consumer Secret" v-model="consumerSecret"></v-text-field>
          <v-text-field label="Xero Crypto Account Code" v-model="accountCode"></v-text-field>
          <v-text-field label="Xero Crypto Fee Account Code" v-model="feeAccountCode"></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';
export default {
  props: ['refresh'],
  data() {
    return {
      consumerKey: '',
      consumerSecret: '',
      showDialog: false,
      accountCode: '',
      feeAccountCode: '',
    };
  },
  computed: {
    canSave() {
      return this.consumerKey && this.consumerSecret && this.accountCode && this.feeAccountCode;
    },
  },
  watch: {},
  methods: {
    hideDialog() {
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          createXero: {
            consumerKey: this.consumerKey,
            consumerSecret: this.consumerSecret,
            accountCode: this.accountCode,
            feeAccountCode: this.feeAccountCode,
          },
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create)
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.consumerKey = '';
          this.consumerSecret = '';
          this.accountCode = '';
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
  },
};
</script>
