















import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import Connections from '../../components/org/AccountingConnections.vue';

@Component({
  components: {
    Connections,
  },
})
export default class ConnectionsClass extends BaseVue {
  public selectedTab = 0;
}
