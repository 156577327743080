<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectNetSuite')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <v-text-field label="Name" v-model="name"></v-text-field>
          <v-text-field label="NetSuite Account" v-model="account"></v-text-field>
          <v-text-field label="Subsidiary Internal Id" v-model="subsidiary"></v-text-field>
          <v-text-field label="Digital Asset Account Code" v-model="assetAccountCode"></v-text-field>
          <v-text-field label="Fee Account Code" v-model="feeAccountCode"></v-text-field>
          <v-text-field label="Consumer Key" v-model="consumerKey"></v-text-field>
          <v-text-field label="Consumer Secret" v-model="consumerSecret"></v-text-field>
          <v-text-field label="Token Key" v-model="tokenKey"></v-text-field>
          <v-text-field label="Token Secret" v-model="tokenSecret"></v-text-field>
          <div>
            <div v-for="(pair, index) in keyValuePairs" :key="index" class="key-value-pair">
              <v-text-field class="key-field" v-model="pair.key"></v-text-field>
              <v-text-field class="value-field" v-model="pair.value"></v-text-field>
              <v-btn @click="deleteKeyValuePair(index)" class="delete-button">Delete</v-btn>
            </div>
            <v-btn @click="addKeyValuePair">Add Account Mapping</v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<style scoped>
.key-value-pair {
  display: flex;
  align-items: center;
}

.key-field {
  flex: 1;
  margin-right: 10px;
}

.value-field {
  flex: 1;
  margin-right: 10px;
}

.delete-button {
  flex-shrink: 0;
}
</style>

<script>
import gql from 'graphql-tag';
export default {
  props: ['refresh'],
  data() {
    return {
      name: '',
      account: '',
      assetAccountCode: '',
      subsidiary: '',
      feeAccountCode: '',
      consumerKey: '',
      consumerSecret: '',
      tokenKey: '',
      tokenSecret: '',
      showDialog: false,
      exchangeContactId: '',
      keyValuePairs: [],
      showDuplicateKeyWarning: false,
    };
  },
  computed: {
    canSave() {
      const requiredFields = [
        this.name,
        this.account,
        this.assetAccountCode,
        this.feeAccountCode,
        this.consumerKey,
        this.consumerSecret,
        this.tokenKey,
        this.tokenSecret,
      ];

      const allKeyValuePairsValid = this.keyValuePairs.every((pair) => pair.key && pair.value);

      const existingKeys = this.keyValuePairs.map((pair) => pair.key);
      const uniqueKeys = new Set(existingKeys);
      const noDuplicates = uniqueKeys.size === this.keyValuePairs.length;

      return requiredFields.every((field) => field) && allKeyValuePairsValid && noDuplicates;
    },
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.name = '';
      this.account = '';
      this.subsidiary = '';
      this.assetAccountCode = '';
      this.feeAccountCode = '';
      this.consumerKey = '';
      this.consumerSecret = '';
      this.tokenKey = '';
      this.tokenSecret = '';
    },
    hideDialog() {
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    addKeyValuePair() {
      this.keyValuePairs.push({});
    },
    deleteKeyValuePair(index) {
      this.keyValuePairs.splice(index, 1);
    },
    closeDuplicateKeyWarning() {
      this.showDuplicateKeyWarning = false;
    },
    save() {
      const keyValuePairsObj = {};
      this.keyValuePairs.forEach((pair) => {
        // Use arrow function to maintain `this` context
        keyValuePairsObj[pair.key] = pair.value;
      });
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          createNetSuite: {
            account: this.account,
            subsidiary: this.subsidiary,
            assetAccountCode: this.assetAccountCode,
            feeAccountCode: this.feeAccountCode,
            consumerKey: this.consumerKey,
            consumerSecret: this.consumerSecret,
            tokenId: this.tokenKey,
            tokenSecret: this.tokenSecret,
            name: this.name,
            accountMappingConfig: keyValuePairsObj,
          },
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create)
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.resetForm();
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
  },
};
</script>
