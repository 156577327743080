<template>
  <div class="pa-1">
    <template v-if="!editNetSuiteConnectionNew && !editSageIntacctConnection">
      <v-layout align-center justify-end class="pb-3">
        <v-menu offset-y min-width="250" offset-overflow>
          <v-btn slot="activator" color="success" small v-if="checkScope(scopeLiterals.AccountingConnectionCreate)">
            Connect New Account
          </v-btn>
          <v-list>
            <v-list-tile
              v-for="(item, index) in enabledProviders"
              :key="index"
              :disabled="item.divider"
              @click="connect(item.id)"
              v-show="!((item.id === 'qb' || item.id === 'xero') && isConnected(item.id))"
            >
              <img v-if="item.id === 'qb'" src="C2QB_green_btn_short_default.svg" />
              <img v-if="item.id === 'xero'" src="xero-connect-blue.svg" />

              <v-list-tile-title justify="center" v-else>
                <v-layout row wrap v-if="!item.divider && item.id !== 'qb' && item.id !== 'xero'">
                  <v-flex xs3><img style="height: 20px" :src="item.icon" /></v-flex>
                  <v-flex xs9>{{ item.name }}</v-flex>
                </v-layout>
                <v-layout row wrap v-if="item.divider && item.id !== 'qb' && item.id !== 'xero'">
                  <v-flex xs12>{{ item.name }}</v-flex>
                </v-layout>
              </v-list-tile-title>

              <v-divider v-if="item.divider"></v-divider>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-layout>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="activeConnections"
        item-key="id"
        class="elevation-3"
        hide-actions
        :loading="isLoading ? 'accent' : false"
      >
        <template slot="items" slot-scope="cxn">
          <tr>
            <td class="text-xs-left" width="20">
              <template v-if="cxn.item.syncStatus">
                <v-icon v-if="cxn.item.syncStatus.isRunning" class="fa-spin">refresh</v-icon>
                <v-icon
                  v-else-if="cxn.item.syncStatus.status === 'Success' && cxn.item.syncStatus.lastSyncCompletedSEC"
                  color="success"
                  >check</v-icon
                >
                <v-icon
                  v-else-if="cxn.item.syncStatus.status === 'NotSynced' || !cxn.item.syncStatus.lastSyncCompletedSEC"
                  color="grey"
                  >schedule</v-icon
                >
                <v-icon v-else-if="cxn.item.syncStatus.status === 'SuccessWithWarnings'" color="warning"
                  >warning</v-icon
                >
                <v-icon v-else-if="cxn.item.syncStatus.status === 'Errored'" color="error">error</v-icon>
              </template>
              <template v-else-if="cxn.item.lastSyncSEC">
                <v-icon color="success">check</v-icon>
              </template>
            </td>
            <td class="text-xs-left" width="20">
              <img
                style="height: 20px"
                :src="getProviderIcon(cxn.item.provider)"
                v-if="cxn.item.provider !== 'Manual'"
              />
              <span v-else>Manual</span>
            </td>
            <td class="text-xs-left" style="white-space: nowrap">
              {{ cxn.item.name ? cxn.item.name : cxn.item.provider }}
            </td>
            <td class="text-xs-left">
              <v-checkbox
                primary
                hide-details
                v-model="cxn.item.isDefault"
                v-on:change="updateDefault(cxn.item)"
                :disabled="isDefaultDisabled(cxn.item)"
              ></v-checkbox>
            </td>
            <td class="text-xs-left">
              <span v-if="cxn.item.isSetupComplete" class="success&#45;&#45;text">
                <v-icon color="success">check</v-icon>
                {{ $t('_complete') }}
              </span>
              <span v-else class="warning&#45;&#45;text">
                <setup-accounting-connection
                  :connectionId="cxn.item.id"
                  :title="getSetupTitle(cxn.item.provider)"
                  :refresh="refresh"
                  :source="cxn.item.provider"
                />
              </span>
            </td>
            <td class="text-xs-left" style="white-space: nowrap">
              <template v-if="cxn.item.syncStatus">
                <span :class="getStatusColor(cxn.item.syncStatus.status)">
                  {{ toPreferredDateTime(cxn.item.syncStatus.lastSyncCompletedSEC) }}
                </span>
              </template>
              <template v-else-if="cxn.item.lastSyncSEC">
                <span class="success--text">
                  {{ toPreferredDateTime(cxn.item.lastSyncSEC) }}
                </span>
              </template>
            </td>
            <td>
              <template v-if="cxn.item.syncStatus">
                <ul style="color: orange">
                  <li v-for="(warning, ei) in cxn.item.syncStatus.warnings" v-bind:key="`w-${cxn.item.id}-${ei}`">
                    {{ warning }}
                  </li>
                </ul>
                <ul style="color: red">
                  <li v-for="(error, ei) in cxn.item.syncStatus.errors" v-bind:key="`e-${cxn.item.id}-${ei}`">
                    {{ error }}
                  </li>
                </ul>
                <span
                  v-if="cxn.item.syncStatus.status === 'Success' && cxn.item.syncStatus.lastSyncCompletedSEC"
                  class="success--text"
                >
                  Sync successful.
                </span>
                <span v-if="cxn.item.syncStatus.status === 'Disabled'" class="warning--text">
                  Disabled. Reconnect to enable it.
                </span>
              </template>
              <template v-else-if="cxn.item.lastSyncSEC && !cxn.item.isDisabled">
                <span class="success--text"> Successfully Synced </span>
              </template>
              <template v-else-if="!cxn.item.isDisabled">
                <i>Unsynced</i>
              </template>
              <template v-else>
                <i>Disconnected</i>
              </template>
            </td>
            <td class="text-xs-right">
              <div class="tw-flex tw-justify-end">
                <span
                  v-if="cxn.item.isSetupComplete && !cxn.item.isDisabled && isEditableConnection(cxn.item.provider)"
                >
                  <setup-accounting-connection
                    :connectionId="cxn.item.id"
                    :title="getSetupTitle(cxn.item.provider, true)"
                    :refresh="refresh"
                    :source="cxn.item.provider"
                    :connectionData="cxn.item"
                    :btnName="'_edit'"
                    :btnOutline="false"
                    :btnClass="'tw-px-2'"
                    :btnIcon="'edit'"
                  />
                </span>
                <template v-if="cxn.item.provider === 'NetSuite'">
                  <v-btn @click="editCredentials(cxn)"> Update Credentials </v-btn>
                  <v-btn
                    v-if="!cxn.item.isDisabled && enableNetSuiteCustomFields"
                    color="Info"
                    @click="
                      editNetSuiteConnectionNew = true;
                      editNetSuite(cxn.item);
                    "
                    >Edit Settings</v-btn
                  >
                </template>
                <v-btn v-if="cxn.item.provider === 'SageIntacct'" @click="editSageIntacctConnection = cxn.item">
                  Edit Settings
                </v-btn>
                <v-btn
                  color="Info"
                  v-if="['QuickBooks', 'Xero'].indexOf(cxn.item.provider) > -1 && !cxn.item.isDisabled"
                  @click="disconnect(cxn.item)"
                  >Disconnect</v-btn
                >
                <template v-if="cxn.item.isDisabled">
                  <div
                    v-if="cxn.item.provider === 'QuickBooks'"
                    class="tw-px-2 hover:tw-cursor-pointer"
                    @click="reconnect(cxn.item)"
                  >
                    <img src="C2QB_green_btn_short_default.svg" alt="Connect to QuickBooks button" />
                  </div>
                  <v-btn color="Info" v-else @click="reconnect(cxn.item)">Reconnect</v-btn>
                </template>
                <v-btn color="Info" v-if="!cxn.item.isDisabled" @click="sync(cxn.item)">Sync</v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <edit-netsuite-connection-new
      v-if="editNetSuiteConnectionNew"
      :connection="editNetSuiteConnection"
      @back="
        editNetSuiteConnectionNew = false;
        editNetSuiteConnection = null;
      "
      :refresh="refresh"
    ></edit-netsuite-connection-new>

    <edit-sage-intacct-connection
      v-if="!!editSageIntacctConnection"
      :connection="editSageIntacctConnection"
      @back="editSageIntacctConnection = null"
      :refresh="refresh"
    ></edit-sage-intacct-connection>

    <edit-connection
      ref="editConnectionDialog"
      :refresh="refresh"
      :connectionId="editConnectionId"
      :connectionProvider="editConnectionProvider"
      :editConnectionStatus="editConnectionStatus"
    >
    </edit-connection>

    <connect-xero ref="xeroDialog" :refresh="refresh"></connect-xero>
    <connect-net-suite ref="netSuiteDialog" :refresh="refresh"></connect-net-suite>
    <connect-sage-intacct ref="sageIntacctDialog" :refresh="refresh"> </connect-sage-intacct>
    <connect-request ref="requestDialog" :refresh="refresh"></connect-request>
  </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';

import { Providers } from '@/api-svc-types';
import { ErroredTransactionsQuery } from '@/queries/transactionsPageQuery';
import { getAccountingProviderIcon, isAccountingProvider } from '@/utils/accountingProviders';

import { reconnectToken } from '../../mutations/connectionMutation';
import { MUT_SNACKBAR } from '../../store';
import ConnectNetSuite from './ConnectNetSuite';
import ConnectRequest from './ConnectRequest';
import ConnectSageIntacct from './ConnectSageIntacct';
import ConnectXero from './ConnectXero';
import EditNetsuiteConnectionNew from './EditNetsuiteConnectionNew.vue';
import EditSageIntacctConnection from './EditSageConnection.vue';
import EditConnection from './modals/EditConnection.vue';
import SetupAccountingConnection from './SetupAccountingConnection';

export default {
  props: [],
  components: {
    ConnectNetSuite,
    ConnectXero,
    ConnectSageIntacct,
    SetupAccountingConnection,
    EditNetsuiteConnectionNew,
    ConnectRequest,
    EditConnection,
    EditSageIntacctConnection,
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          value: 'provider',
        },
        {
          text: 'Provider',
          align: 'left',
          value: 'logo',
        },
        {
          text: 'Name',
          align: 'left',
          value: 'logo',
        },
        {
          text: 'Default',
          align: 'left',
          value: 'isDefault',
        },
        {
          text: 'Setup status',
          value: 'isSetupComplete',
        },
        {
          text: 'Last Sync',
          align: 'left',
          value: 'lastsynced',
        },
        {
          text: 'Sync Notes',
          align: 'left',
          value: 'sync',
        },
        {
          text: '',
          align: 'right',
          value: 'actions',
        },
      ],
      connections: [],
      providers: [
        {
          // label as it appears in the UI as group header
          name: 'Accounting Systems',
          divider: true,
        },
        {
          id: 'qb',
          name: 'QuickBooks Online',
          provider: Providers.QuickBooks,
          connection: {},
          isConnected: false,
          icon: '/qb.png',
        },
        {
          id: 'xero',
          name: 'Xero',
          provider: Providers.Xero,
          connection: {},
          isConnected: false,
          icon: '/xero.png',
        },
        {
          id: 'netsuite',
          name: 'NetSuite',
          provider: Providers.NetSuite,
          connection: {},
          isConnected: false,
          icon: '/netsuite.png',
        },
        {
          id: 'sageIntacct',
          name: 'Sage Intacct',
          provider: Providers.SageIntacct,
          connection: {},
          isConnected: false,
          icon: '/sageintacct.png',
        },
        {
          id: 'request',
          name: 'Request Finance',
          provider: Providers.Request,
          connection: {},
          isConnected: false,
          icon: '/request.png',
        },
      ],
      isLoading: 0,
      remoteConnections: [],
      editNetSuiteConnection: null,
      editNetSuiteConnectionNew: false,
      editSageIntacctConnection: null,
      editConnectionId: null,
      editConnectionStatus: null,
      editConnectionProvider: null,
    };
  },
  watch: {
    connections: {
      handler() {
        // console.log('watch: connections', this.connections);
        this.remoteConnections = this.connections.filter((c) => !c.isDeleted && !isAccountingProvider(c.provider));
      },
      immediate: true,
    },
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
            accountCode
            feeAccountCode
            connectionSpecificFields
            isDefault
            status
          }
        }
      `,
      /* -- Added on the bitgo branch I think
            isSetupComplete
            multiWalletSyncMode
            enabledWallets
       */
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
    rules: {
      query: gql`
        query rules($orgId: ID!) {
          rules(orgId: $orgId) {
            id
            name
            disabled
            accountingConnectionId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      onError(error) {
        return { error: error.message };
      },
      loadingKey: 'isLoading',
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    getSetupTitle(provider, isEdit = false) {
      let key = '_finishSetup';

      if (isEdit) {
        key = '_editSetup';

        return `${this.$t(key)} (${provider})`;
      } else {
        switch (provider) {
          case 'Xero':
            key = '_finishXeroSetup';
            break;
          case 'QuickBooks':
            key = '_finishQBSetup';
            break;
          // case 'NetSuite':
          //   key = '_finishNetSuiteSetup';
          //   break;
          // case 'Sage Intacct':
          //   key = '_finishSageIntacctSetup';
          //   break;
          // case 'Request Finance':
          //   key = '_finishRequestSetup';
          //   break;
          default:
            key = '_finishSetup';
            break;
        }

        return this.$t(key);
      }
    },
    getStatusColor(status) {
      if (status === 'Success') {
        return 'success--text';
      } else if (status === 'SuccessWithWarnings') {
        return 'orange--text';
      } else if (status === 'Errored') {
        return 'failure--text';
      } else {
        return '';
      }
    },
    getProviderIcon(provider) {
      return getAccountingProviderIcon(provider);
    },
    // toLocalTime(time) {
    //   if (time) {
    //     return toLocalDateTime(time);
    //   } else {
    //     return 'Never Synced';
    //   }
    // },
    refresh() {
      this.$apollo.queries.connections.refetch();
    },
    async disconnect(item) {
      const confirmAnswer = confirm(`Are you sure you want to disconnect ${item.provider}?`);
      if (!confirmAnswer) {
        return;
      }

      this.connections = this.connections.map((c) => (c.id === item.id ? item : c));

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation revokeToken($orgId: ID!, $connectionId: ID!) {
            revokeToken(orgId: $orgId, connectionId: $connectionId) {
              success
              error
            }
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          connectionId: item.id,
        },
      });
      if (resp.data.revokeToken.success === true) {
        item.isDisabled = true;
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Disconnection successful.',
        });
      } else {
        const msg = 'Problem disconnecting connection';
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: msg,
        });
      }
    },
    async sync(item) {
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $connectionId: ID!) {
            syncConnection(orgId: $orgId, connectionId: $connectionId) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          connectionId: item.id,
        },
      });
      if (resp.data.syncConnection.success === true) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Connection Sync Initiated',
        });
      } else {
        let msg = 'Problem initiating connection sync';

        if (resp.data.errors) {
          msg = resp.data.errors.join('<br />');
        }

        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: msg,
        });
      }
    },
    async reconnect(item) {
      // 1. start connection with provider
      // 2. receive callback from provider and un-disable connection
      const cxnId = this.providers.find((p) => p.provider === item?.provider).id;

      if (!cxnId) {
        console.error('No connection id found for provider:', item.provider);
        this.showErrorSnackbar('Problem reconnecting: No connection id found for provider: ' + item.provider);
        return;
      }

      const provider = item.provider.toLowerCase();

      try {
        // connect to provider
        await this.connect(cxnId);

        if (provider === 'quickbooks') {
          const variables = {
            orgId: this.$store.state.currentOrg.id,
            connectionId: item.id,
          };
          this.connections = this.connections.map((e) => {
            if (e.isDisabled) e.isDisabled = !e.isDisabled;
            return e;
          });
          const mutation = await reconnectToken;
          await this.$apollo
            .mutate({
              mutation,
              variables,
            })
            .catch((e) => {
              console.error('Error reconnecting | Store values:', e);
            });
        } else {
          console.error('Throw Error');
        }
      } catch (error) {
        console.error('Error reconnecting | Provider (' + provider + '):', error);
      }
    },
    async connect(cxnId) {
      if (cxnId === 'netsuite') {
        this.$refs.netSuiteDialog.show();
      } else if (cxnId === 'sageIntacct') {
        this.$refs.sageIntacctDialog.show();
      } else if (cxnId === 'request') {
        this.$refs.requestDialog.show();
      } else {
        console.log('Getting URL for redirect:', cxnId);
        const url = this.getFunctionsBaseUrl() + 'app/org/connections/connect?service=' + cxnId;
        // console.log('URL:', url);
        // const options = {
        //   withCredentials: true,
        // };

        const data = {
          orgId: this.$store.state.currentOrg.id,
        };

        const resp = await axios({
          method: 'POST',
          url,
          withCredentials: true,
          data,
        });

        // const resp = await axios.post(url, data, options);
        if (resp.status === 200) {
          const body = resp.data;
          // const b = JSON.parse(body);
          window.location.href = body.connectUrl;
        } else {
          console.error(resp.data);
          return false;
        }
      }
    },
    isConnected(cxnId) {
      const provider = cxnId === 'qb' ? 'quickbooks' : cxnId;
      if (this.connections.length > 0) {
        const isConnect = this.connections.some(
          (m) => m.provider.toLowerCase() === provider && m.isSetupComplete && !m.isDisabled
        );
        return isConnect;
      }

      return false;
    },
    editNetSuite(cxn) {
      this.editNetSuiteConnection = cxn;
    },
    isDefaultDisabled(cxn) {
      let disabled = false;
      if (cxn.isDefault) {
        return false;
      } else {
        const connections = this.activeConnections;
        connections.map((m) => {
          if (m.isDefault) {
            disabled = true;
          }
        });
      }
      return disabled;
    },
    async updateDefault(cxn) {
      // Update the default connection
      const confirmAnswer = confirm('Are you sure you want to change the default connection?');
      if (!confirmAnswer) {
        return;
      }

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation (
            $orgId: ID!
            $connectionId: ID!
            $accountCode: String
            $feeAccountCode: String
            $connectionSpecificFields: AccountingConnectionSpecificFields
            $isDefault: Boolean
          ) {
            updateAccountingConnection(
              orgId: $orgId
              connectionId: $connectionId
              accountCode: $accountCode
              feeAccountCode: $feeAccountCode
              connectionSpecificFields: $connectionSpecificFields
              isDefault: $isDefault
            )
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          connectionId: cxn.id,
          accountCode: undefined,
          feeAccountCode: undefined,
          connectionSpecificFields: undefined,
          isDefault: cxn.isDefault,
        },
      });
      if (resp.errors) {
        this.showErrorSnackbar('Problem updating connection: ' + resp.errors.join('<br />'));
      }
    },

    async getUnresolvedTransactions(connectionId) {
      const resp = await this.$apollo.mutate({
        // Query
        mutation: ErroredTransactionsQuery,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          transactionFilter: {
            categorizationFilter: 'Categorized',
            reconciliationFilter: 'Unreconciled',
            connectionFilter: connectionId,
          },
          limit: 1,
        },
      });
      if (resp.errors) {
        this.showErrorSnackbar('Problem getting transactions ' + resp.errors.join('<br />'));
      }
      return resp.data.transactions.txns;
    },
    // TODO: Refactor to utilize Babel instead of apollo - we can't query for unresolved txns by conection ID in firestore
    // TODO: Reconnect this function and restore 'disable' checkbox after switching to use with Babel
    async disableConnection(cxn) {
      // first confirm that there are no categorized transactions that are unreconciled
      const unresolvedTransactions = await this.getUnresolvedTransactions(cxn.id);
      if (unresolvedTransactions.length > 0) {
        alert(`Cannot disable: Categorized transactions for the connection are still unreconciled.`);
      } else {
        // Ask user for confirmation
        const confirmAnswer = confirm('Are you sure you want to disable the connection?');
        if (!confirmAnswer) {
          return;
        }
        // Disable any associated rules before disabling the connection
        try {
          const queryRuleResp = await this.$apollo.queries.rules.refetch();
          const rules = queryRuleResp.data.rules;
          const [{ error }] = await this.disableAssociatedRules(cxn.id, rules);
          if (error) {
            throw new Error(error);
          }
        } catch (e) {
          console.error(e);
          alert(`Cannot Disable: Failed to disable associated rules.`);
          return;
        }

        // Disable the connection
        const resp = await this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation (
              $orgId: ID!
              $isDisabled: Boolean
              $connectionId: ID!
              $accountCode: String
              $feeAccountCode: String
              $connectionSpecificFields: AccountingConnectionSpecificFields
              $isDefault: Boolean
            ) {
              updateAccountingConnection(
                orgId: $orgId
                connectionId: $connectionId
                accountCode: $accountCode
                feeAccountCode: $feeAccountCode
                connectionSpecificFields: $connectionSpecificFields
                isDefault: $isDefault
                isDisabled: $isDisabled
              )
            }
          `,
          // Parameters
          variables: {
            orgId: this.$store.state.currentOrg.id,
            connectionId: cxn.id,
            accountCode: undefined,
            feeAccountCode: undefined,
            connectionSpecificFields: undefined,
            isDefault: cxn.isDefault,
            isDisabled: true,
          },
        });
        if (resp.errors) {
          this.showErrorSnackbar('Problem disabling connection: ' + resp.errors.join('<br />'));
        }
        // Refresh the displayed connection data
        await this.$apollo.queries.connections.refetch();
      }
    },
    async disableAssociatedRules(accountingConnectionId, rules) {
      // Get id of rules associated with the disabled accounting connection
      const associatedRuleIds = rules
        .filter((rule) => {
          return rule.accountingConnectionId === accountingConnectionId;
        })
        .map((rule) => {
          return rule.id;
        });
      // Disable all the collected rule ids associated with the accounting connection
      const resp = associatedRuleIds.map((ruleId) => {
        const orgId = this.$store.state.currentOrg.id;
        return this.$apollo
          .mutate({
            mutation: gql`
              mutation toggleRuleStatus($orgId: ID!, $ruleId: ID!, $disabled: Boolean!) {
                toggleRuleStatus(orgId: $orgId, ruleId: $ruleId, disabled: $disabled)
              }
            `,
            variables: {
              orgId,
              ruleId,
              disabled: true,
            },
          })
          .catch((error) => {
            return { error: error.message };
          });
      });
      return Promise.all(resp);
    },
    isEditableConnection(provider) {
      const activeFeatureFlag = true; // this.checkFeatureFlag('accounting-connection-edit', this.features);
      const activeProviders = ['QuickBooks', 'Xero'];
      return activeProviders.indexOf(provider) > -1 && activeFeatureFlag;
    },
    editCredentials(cxn) {
      // set connection id
      this.editConnectionId = cxn.item.id;
      this.editConnectionProvider = cxn.item.provider;
      this.editConnectionStatus = cxn.item.syncStatus.status;

      // open edit dialog
      this.$refs.editConnectionDialog.show();
    },
  },
  computed: {
    activeConnections() {
      return this.connections.filter((m) => !m.isDeleted && isAccountingProvider(m.provider));
    },
    contacts() {
      return this.$store.getters['contacts/CONTACTS'];
    },
    enabledProviders() {
      return this.providers.filter((p) => {
        if (p.enabled === undefined) {
          return true;
        }
        return this.checkFeatureFlag(p.id, this.features);
      });
    },
    enableNetSuiteCustomFields() {
      return this.checkFeatureFlag('netsuite-custom-fields', this.features);
    },
  },
};
</script>
<style>
.quickbook-button {
  border: none;
  padding: 0px 5px;
  height: 28px;
}
.quickbook-button img {
  height: 29px;
}
.qb-disconnect-button {
  background: #2ca01c;
  color: #ffff;
  padding: 2px;
  border-radius: 5px;
}
@media screen and (min-width: 100px) and (max-width: 500px) {
  .v-menu__activator {
    flex-direction: column;
  }
  .quickbook-button img {
    height: 25px;
  }
}
</style>
